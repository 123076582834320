// istanbul ignore file -- No need to test GraphQL query.
import { gql } from '@ori/fetching';

export const catalogueFragment = gql`
  fragment Catalogue on CatalogueInfo {
    catalogueType
    title
    imageUrl
    validFrom
    validTo
    link {
      relativeUrl
      dynamicSharingUrl
    }
  }
`;

export const cataloguesLandingPageQuery = gql`
  query CataloguesLandingPage {
    application {
      catalogueLandingPage {
        digitalCatalogues {
          ...Catalogue
        }
        otherCatalogues {
          ...Catalogue
        }
      }
    }
  }
  ${catalogueFragment}
`;

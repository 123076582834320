import * as OpenFeedbackBenefitDialog from '@ori-events/open-feedback-benefit-dialog';
import { Share } from '@ori-ui/icons';
import { isBeautyApp } from '@ori/beauty-app-info';
import { ShareButton as ButtonShare } from '@ori/presentation-components';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';
import { memo } from 'react';

import { NATIVE_SHARE_URL } from '../../constants/urls';
import { useCatalogueUrlAndId, usePageNumberContext } from '../../hooks';
import { useTranslations } from '../../hooks/useTranslations';
import { FeedbackBenefitBannerType } from '../../models/graphql-types/net';
import { getPageNumber } from '../PageNavigator';
import { NATIVE_SHARE } from './constants';
import { NativeShare } from './styles';

const openFeedbackBenefitDialog = (detail: OpenFeedbackBenefitDialog.Data) => () => {
  window.dispatchEvent(
    new CustomEvent<OpenFeedbackBenefitDialog.Data>(OpenFeedbackBenefitDialog.Name, {
      detail,
    }),
  );
};

export const ShareButton: FC = memo(() => {
  const { getTestId } = useTestId();
  const { translations } = useTranslations();
  const { currentPages } = usePageNumberContext();
  const cataloguePageNumber = Number(getPageNumber(currentPages)) || 1;
  const { catalogueId } = useCatalogueUrlAndId();

  if (isBeautyApp()) {
    return (
      <NativeShare
        aria-label={translations.shareTheCatalogue}
        as="a"
        href={NATIVE_SHARE_URL}
        data-testid={getTestId(NATIVE_SHARE)}
      >
        <Share fontSize="inherit" />
      </NativeShare>
    );
  }

  return (
    <ButtonShare
      aria-label={translations.shareTheCatalogue}
      onClick={openFeedbackBenefitDialog({
        type: FeedbackBenefitBannerType.ShareAndEarn,
        cataloguePageNumber,
        catalogueId,
      })}
    />
  );
});
